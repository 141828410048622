import * as React from 'react';
import { Image } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedImagesProps {
  images: Image[];
  onRemove: (entity: Entity) => void;
}

const RelatedImages: React.FC<RelatedImagesProps> = ({ images, onRemove }) => {
  const onRemoveImage = (
    e: React.MouseEvent<HTMLButtonElement>,
    image: Image
  ): void => {
    e.preventDefault();
    onRemove(image);
  };

  if (images.length > 0) {
    return (
      <>
        <h3 className="medium-title flex justify-between items-center">
          <span>Images</span>
          <span>
            <a
              href={`${window.location.pathname}/sort/Image`}
              className="btn"
            >
              Sort Images &rarr;
            </a>
          </span>
        </h3>
        <Table headings={['ID', 'Name', 'Actions']}>
          {images.map((image) => (
            <tr
              key={`${image.__typename}-${image.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{image.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">{image.name}</td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveImage(e, image)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedImages;
