import * as React from 'react';
import { Fragment } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';

interface MenuLink {
  text: string;
  path: string;
  method?: string;
}

export interface UserProps {
  isLoggedIn: boolean;
  name: string;
  email: string;
  avatarUrl: string;
  menuLinks: MenuLink[];
  signInPath: string;
  signUpPath: string;
}

const UserMenu: React.FC<UserProps> = (props) => {
  const { isLoggedIn, email, name, avatarUrl } = props;

  // if not logged in, we need to show the sign in and sign up links instead
  if (!isLoggedIn) {
    return (
      <div className="flex justify-center sm:justify-end items-center space-x-2 ml-0 sm:ml-2 mr-0 sm:mr-4 cta">
        <a
          href={props.signInPath}
          className="text-white px-4 py-3 rounded-md border border-slate-500 hover:border-slate-200"
        >
          Sign In
        </a>
        <a href={props.signUpPath} className="btn-callout">
          Sign Up
        </a>
      </div>
    );
  }

  return (
    <div className="flex-shrink-0 flex hover:bg-slate-900 px-6 h-16 sm:h-24 ml-0 sm:ml-4">
      <Menu>
        {({ open }) => (
          <div className="flex-shrink-0 w-full group block">
            <div>
              <Menu.Button className="flex items-center h-16 sm:h-24 focus:outline-none">
                <div>
                  <img
                    src={avatarUrl}
                    className="inline-block h-10 w-10 rounded-full"
                  />
                </div>
                <div className="ml-4 text-left">
                  <p className="text-sm font-medium text-white">{name}</p>
                  <p className="text-xs text-slate-300 group-hover:text-slate-200">
                    {email}
                  </p>
                </div>
                <div className="ml-4 text-right">
                  {!open && <ChevronDownIcon className="h-6 w-6 text-white" />}
                  {open && <ChevronUpIcon className="h-6 w-6 text-white" />}
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-10 right-0 sm:right-2 w-4/5 sm:w-64 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1"></div>

                {props.menuLinks.map((link, i) => (
                  <Menu.Item key={i}>
                    {() => {
                      const attrs = {};

                      if (link.method) {
                        attrs['data-method'] = link.method;
                      }

                      return (
                        <a
                          href={link.path}
                          {...attrs}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          {link.text}
                        </a>
                      );
                    }}
                  </Menu.Item>
                ))}

                <div className="px-1 py-1"></div>
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
