import * as React from 'react';
import { Document } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedDocumentsProps {
  documents: Document[];
  onRemove: (entity: Entity) => void;
}

const RelatedDocuments: React.FC<RelatedDocumentsProps> = ({
  documents,
  onRemove,
}) => {
  const onRemoveDocument = (
    e: React.MouseEvent<HTMLButtonElement>,
    document: Document
  ): void => {
    e.preventDefault();
    onRemove(document);
  };

  if (documents.length > 0) {
    return (
      <>
        <h3 className="medium-title flex justify-between items-center">
          <span>Documents</span>
          <span>
            <a
              href={`${window.location.pathname}/sort/Document`}
              className="btn"
            >
              Sort Documents &rarr;
            </a>
          </span>
        </h3>
        <Table headings={['ID', 'Name', 'Actions']}>
          {documents.map((document) => (
            <tr
              key={`${document.__typename}-${document.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{document.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">{document.name}</td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveDocument(e, document)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedDocuments;
